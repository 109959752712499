import React from "react"
import { Link } from "gatsby"
import { rhythm } from "../../utils/typography"
import { FooterWrap, Img } from "./style"
import Writone from "./writone.svg"
import "../main.css"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header
    if (location.pathname === rootPath) {
      header = (
        <h1 style={{ paddingLeft: `1rem`, margin: `1.5rem 0 ` }}>
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <p style={{ paddingLeft: `1rem`, margin: `1.5rem 0 ` }}>
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            ＜ {title}
          </Link>
        </p>
      )
    }
    return (
      <div style={{ position: "relative" }}>
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(24),
            padding: `${rhythm(1.5)} 0 ${rhythm(5)} `,
          }}
        >
          <header>
            <p style={{ paddingLeft: `1rem` }}>
              <a
                href="https://www.writone.com/"
                style={{
                  boxShadow: `none`,
                  textDecoration: `underline`,
                }}
              >
                Writoneに戻る
              </a>
            </p>
            {header}
          </header>
          <main>{children}</main>
          <Footer />
        </div>
      </div>
    )
  }
}
export default Layout

const Footer = () => {
  return (
    <footer>
      <FooterWrap>
        <a href="https://www.writone.com" style={{ boxShadow: "none" }}>
          <Img src={Writone} alt={"logo"} />
        </a>
        <small>
          © {new Date().getFullYear()},{` `}
          Writone All rights reserved.
        </small>
      </FooterWrap>
    </footer>
  )
}
