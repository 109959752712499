import styled from "styled-components"

export const Row = styled.div`
  display: flex;
`

export const Col = styled(Row)`
  flex-direction: column;
`

export const ColCenter = styled(Col)`
  align-items: center;
`

export const Card = styled(Row)`
  border-bottom: 1px solid #e5e5ea;
  padding: 1.5rem 1rem;
`

export const Img = styled.div`
  width: 5rem;
  height: 5rem;
  min-width: 5rem;
  min-height: 5rem;
  border-radius: 0.8rem;
  background-image: ${props =>
    `url(${props.src ||
      "https://storage.googleapis.com/writone-ja-admin/update-min.jpg"})`};
  background-size: cover;
  margin-right: 1rem;
  @media screen and (min-width: 768px) {
    width: 8rem;
    height: 8rem;
    min-width: 8rem;
    min-height: 8rem;
    margin-right: 2rem;
  }
`
