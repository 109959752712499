import styled from "styled-components"
import { ColCenter } from "../main"

export const FooterWrap = styled(ColCenter)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f2f2f7;
  padding: 1rem;
`

export const Img = styled.img`
  margin-bottom: 0.5rem;
`
